import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { CSVLink } from "react-csv"

import { Flex, Heading, Paragraph } from 'reakit'

import { Container, Sidebar, Body, Footer } from '../../../components/layouts'

import { DynamicTable, Card, Loader, Image, Button } from '../../../components/ui'

import { getDonorSum, refreshToken } from '../../../factory'

import currencyFormat from '../../../util/currencyFormat'

import Layout from '../../../components/layout'

import theme from '../../../theme/content'

class Donors extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			bodySize: 0,
			update: false,
			donorDonations: [],
			userHidden: false,
			donorHidden: false,
			guestHidden: false
		}
		this.updateTable = this.updateTable.bind(this)
		this.getDonors = this.getDonors.bind(this)
	}

	componentDidMount() {
		this.props.refreshToken(this.props.reToken).then((res)=> {
			this.getDonors(res)
		})

		this.updateTable(this.props.stats.donorDonations)
	}

	componentWillReceiveProps(nextProps) {
		const {donorDonations} = nextProps.stats
		if (donorDonations && donorDonations.length !== this.state.donorDonations.length) {
			this.updateTable(donorDonations)
		}
	}

	updateTable(donorDonations) {
		this.setState({donorDonations})
	}

	getDonors(token) {
  	const date_from = moment("2018-01-01T00:00:00").format('MM-DD-YY') + " 00:00:00"
  	const date_to = moment().format('MM-DD-YY') + " 23:59:59"

  	if (this.props.organization) {
  		const statsParams = {
	  		organization_id: this.props.organization.id,
	  		date_from,
	  		date_to,
	  	}

			const queryString = Object.keys(statsParams).map(key => key + '=' + statsParams[key]).join('&')
	  	this.props.onGetDonorSum(queryString, token ? token : this.props.token)
  	}
	}

	filterUsersFromDonors(userList) {
		var donors = []
		var guests = []

		if (userList === null) {
			return {donors, guests}
		}

		donors = userList.filter((user) => !user.guest)
		guests = userList.filter((user) => user.guest)

		return {donors, guests}
	}

	downloadDonors(donorList) {
		if (donorList === null) {
			return []
		}

		var csvDonors = donorList.map((donor) => {
			const tempDonor = Object.assign({}, donor)
			delete tempDonor.organization_id
			delete tempDonor.guest
			return tempDonor
		})


		return csvDonors
	}

	render() {
		const {pending, organization} = this.props
		const {donorHidden, guestHidden, donorDonations} = this.state

		if (pending) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row marginBottom="2em" width="100%">
									<Card padding="1em" width="100%">
										<Flex row justifyContent="space-between">
											<Flex style={{flex: 3}}>
												<div style={{display: 'flex'}}>
													<Heading as="h3" margin="0 0 0.5em 0">Loading Donors...</Heading>
													<Loader/>
												</div>
											</Flex>
										</Flex>
										<DynamicTable data={users} columns={[
											{ Header: 'First Name', accessor: 'f_name' },
											{ Header: 'Last Name', accessor: 'l_name' },
											{ Header: 'Phone', accessor: 'phone' },
											{ Header: 'Email', accessor: 'email' },
											{ Header: '$ Donated', accessor: 'total_donated', Cell: props => currencyFormat(props.value) },
										]}
										sortBy={[
											{
												id: "total_donated",
												desc: true
											}
										]}
										paginate
										maxRows={20}
										/>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}

		if (!this.props.pending && donorDonations.length === 0 && this.props.organization) {
			return (
				<Layout>
					<Container>
						<Sidebar content={theme.adminSidebar} />
						<Body>
							<div style={{minHeight: 'calc(100vh - 200px)'}}>
								<Flex row justifyContent="space-around" marginBottom="1em" width="100%">
									<Card padding="1em" minHeight={'100%'}>
										<Container column >
											<Flex row width="100%" justifyContent={'center'} alignItems={"center"}>
												<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px">Uh Oh! You don't have any donors yet...</Heading>
											</Flex>
											<Flex row justifyContent={'center'} alignItems={"center"} width="100%">
												<Image
													height="250px"
													width="auto"
													src={'https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2%402x.png'}/>
											</Flex>
											{this.props.organization.donor_qty ?
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px" textAlign={'center'}>But you have {this.props.organization.donor_qty} users and they are on their way to becoming donors! Take a look <a style={{color: theme.colors.crusta}} href="/admin/donors/users/">here</a> to see who they are!</Heading>
													<Paragraph style={{textAlign: 'center'}}>Just a heads up, we don't show donors until your donations have completed processing. If your donations are pending (dashed circle), then check back in a few days for a list of donors! </Paragraph>
												</Flex> :
												<Flex column width="100%" justifyContent={'center'} alignItems={"center"} style={{textAlign: 'center'}}>
													<Heading as="h4" margin="auto 0" marginRight="1em" marginBottom="20px" style={{textAlign: 'center'}}>Your first step is to add some donors to the app.</Heading>
													<Paragraph>Need some help? Call us at (737)228-3586, or send us an email at <a href="mailto:nonprofits@flourishchange.com">nonprofits@flourishchange.com</a></Paragraph>
												</Flex>
											}
										</Container>
									</Card>
								</Flex>
							</div>
							<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
								<Footer />
							</Flex>
						</Body>
					</Container>
				</Layout>
			)
		}


		const {donors, users, guests} = this.filterUsersFromDonors(donorDonations)
		const hasDonors = donors && donors.length > 0
		const hasGuests = guests && guests.length > 0

		return (
			<Layout>
				<Container>
					<Sidebar content={theme.adminSidebar} />
					<Body>
						<div style={{minHeight: 'calc(100vh - 200px)'}}>
							{pending &&
								<Flex row marginBottom="2em" width="100%">
									<Card padding="1em" width="100%">
										<Flex row justifyContent="space-between">
											<Flex style={{flex: 3}}>
												<div style={{display: 'flex'}}>
													<Heading as="h3" margin="0 0 0.5em 0">Loading Donors...</Heading>
													<Loader/>
												</div>
											</Flex>
											<DynamicTable data={users} columns={[
												{ Header: 'First Name', accessor: 'f_name' },
												{ Header: 'Last Name', accessor: 'l_name' },
												{ Header: 'Phone', accessor: 'phone' },
												{ Header: 'Email', accessor: 'email' },
												{ Header: '$ Donated', accessor: 'total_donated', Cell: props => currencyFormat(props.value) },
											]}
											sortBy={[
												{
													id: "total_donated",
													desc: true
												}
											]}
											paginate
											maxRows={20}
											/>
										</Flex>
									</Card>
								</Flex>
							}
							{hasDonors &&
						<Flex row marginBottom="2em" width="100%">
							<Card padding="1em" width="100%" marginBottom={donorDonations && donorDonations.length < 25 && "3.3em"}>
								<Flex row justifyContent="space-between">
									<Flex style={{flex: 5}}>
										<div style={{display: 'flex'}}>
											<Heading as="h3" margin="0 0 0.5em 0">Donors</Heading>
											{pending && <Loader/>}
										</div>
										{!pending &&
											<Button style={{marginLeft: 10, maxWidth: 150}}
												onClick={()=>this.setState({donorHidden: !donorHidden})}
											>{donorHidden ? "Show" : "Hide"}</Button>
										}
									</Flex>
									{donors && donors.length > 0  && <CSVLink
										filename={`${organization && organization.title.replace(/ /g, '_')}_Flourish_donors.csv`}
										style={{marginLeft: 15,
											fontFamily: theme.fonts.primary,
											textDecoration: 'none'
										}}
										data={this.downloadDonors(donors)}>
										Download
									</CSVLink>}
								</Flex>
								<div style={{visibility: donorHidden && "hidden", height: donorHidden && 0}}>
									{donors && donors.length > 0 && <DynamicTable data={donors} columns={[
										{ Header: 'First Name', accessor: 'f_name' },
										{ Header: 'Last Name', accessor: 'l_name' },
										{ Header: 'Phone', accessor: 'phone'},
										{ Header: 'Email', accessor: 'email'},
										{ Header: '$ Donated', accessor: 'total_donated', Cell: props => currencyFormat(props.value) },
										{ Header: 'Active', accessor: 'organization_id', Cell: props => props.value === this.props.organization.id ? 'yes' : 'no' },
									]}
									sortBy={[
										{
											id: "total_donated",
											desc: true
										}
									]}
									paginate
									maxRows={20}
									/>}
								</div>
							</Card>
						</Flex>}
							{hasGuests &&
					<Flex row marginBottom="5em" width="100%">
						<Card padding="1em" width="100%" marginBottom={donorDonations && donorDonations.length < 25 && "3.3em"}>
							<Flex row justifyContent="space-between">
								<Flex style={{flex: 5}}>
									<div style={{display: 'flex'}}>
										<Heading as="h3" margin="0 0 0.5em 0">Guest Donors</Heading>
										{pending && <Loader/>}
									</div>
									{!pending &&
										<Button style={{marginLeft: 10, maxWidth: 150}}
											onClick={()=>this.setState({guestHidden: !guestHidden})}
										>{guestHidden ? "Show" : "Hide"}</Button>
									}
								</Flex>
								{guests && guests.length > 0  && <CSVLink
									filename={`${organization.title.replace(/ /g, '_')}_Flourish_guests.csv`}
									style={{marginLeft: 15,
										fontFamily: theme.fonts.primary,
										textDecoration: 'none'
									}}
									data={this.downloadDonors(guests)}>
									Download
								</CSVLink>}
							</Flex>
							<div style={{visibility: guestHidden && "hidden", height: guestHidden && 0}}>
								{guests && guests.length > 0 && <DynamicTable data={guests} columns={[
									{ Header: 'First Name', accessor: 'f_name' },
									{ Header: 'Last Name', accessor: 'l_name' },
									{ Header: 'Email', accessor: 'email' },
									{ Header: '$ Donated', accessor: 'total_donated', Cell: props => currencyFormat(props.value) },
								]}
								sortBy={[
									{
										id: "total_donated",
										desc: true
									}
								]}
								paginate
								maxRows={20}
								/>}
							</div>
						</Card>
					</Flex>}
						</div>
						<Flex flex="0 0" alignItems="start" marginLeft="-1em" width="calc(100% + 2em)" row>
							<Footer />
						</Flex>
					</Body>
				</Container>
			</Layout>
		)
	}
}

Donors.propTypes = {
	onGetDonorSum: PropTypes.func,
	organization: PropTypes.object,
	projects: PropTypes.array,
	stats: PropTypes.object,
	token: PropTypes.string,
	reToken: PropTypes.string,
	pending: PropTypes.bool,
	refreshToken: PropTypes.func
}

const mapStateToProps = (state) => {
	return {
		organization: state.authState.adminOrg || state.adminState.organization,
		projects: state.donateNowState.projects,
		stats: state.statistics,
		token: state.authState.accessToken,
		reToken: state.authState.refreshToken,
		pending: state.statistics.pending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		onGetDonorSum: (params, token) => dispatch(getDonorSum(params, token)),
		refreshToken: (token) => dispatch(refreshToken(token))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Donors)
